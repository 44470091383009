// Import libraries
import React from 'react';


// Import modal and navigation context
import { ModalProvider } from './src/context/modal';
import { MenuProvider } from './src/context/navigation';


// wrapRootElement
// Source: https://www.gatsbyjs.org/docs/browser-apis/#wrapRootElement
export const wrapRootElement = ({ element }) => {
    return (
        <ModalProvider>
            <MenuProvider>{element}</MenuProvider>
        </ModalProvider>
    );
};