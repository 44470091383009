// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-klanten-js": () => import("./../../../src/pages/klanten.js" /* webpackChunkName: "component---src-pages-klanten-js" */),
  "component---src-pages-nieuwsartikelen-js": () => import("./../../../src/pages/nieuwsartikelen.js" /* webpackChunkName: "component---src-pages-nieuwsartikelen-js" */),
  "component---src-pages-over-ons-js": () => import("./../../../src/pages/over-ons.js" /* webpackChunkName: "component---src-pages-over-ons-js" */),
  "component---src-pages-particulier-js": () => import("./../../../src/pages/particulier.js" /* webpackChunkName: "component---src-pages-particulier-js" */),
  "component---src-pages-verstuurd-js": () => import("./../../../src/pages/verstuurd.js" /* webpackChunkName: "component---src-pages-verstuurd-js" */),
  "component---src-pages-zakelijk-js": () => import("./../../../src/pages/zakelijk.js" /* webpackChunkName: "component---src-pages-zakelijk-js" */),
  "component---src-templates-news-article-template-js": () => import("./../../../src/templates/news-article-template.js" /* webpackChunkName: "component---src-templates-news-article-template-js" */)
}

