// Import libraries
import React, { useState, createContext } from 'react';


// Create navigation context
const ModalContext = createContext({
    modalIsOpen: false,
    modalContent: null
});


// Create component
const ModalProvider = ({ children }) => {
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [modalContent, setModalContent] = useState(null);

    return (
        <ModalContext.Provider
            value={{
                modalIsOpen,
                setModalIsOpen,
                modalContent,
                setModalContent
            }}
        >
            {children}
        </ModalContext.Provider>
    );
};


// Exports
export {
    ModalContext as default,
    ModalProvider
};